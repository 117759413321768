.privacy-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-container h2 {
    text-align: center;
    color: #333;
  }
  
  .privacy-container h3 {
    color: rgb(21, 169, 13);
    margin-top: 15px;
  }
  
  .privacy-container p {
    line-height: 1.6;
    color: #555;
  }
  
  .privacy-container a {
    display: block;
    text-align: center;
    margin-top: 20px;
    color: rgb(21, 169, 13);
    font-weight: bold;
    text-decoration: none;
  }
  
  .privacy-container a:hover {
    text-decoration: underline;
  }
  
  /* 响应式设计 */
  @media (max-width: 600px) {
    .privacy-container {
      width: 90%;
      padding: 15px;
    }
  }
  