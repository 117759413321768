* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  padding: 10px 0;
}

html, body {
  height: 100%;
  overflow: hidden; /* 防止滚动条出现 */
}

.container {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;

}

.chat {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  width: 100%;
  align-items: center;
  /* 防止滚动条出现 */
}

.chat-window {
  padding: 10px 0px;
  flex: 1; /* 动态调整高度 */
  width: 800px; /* 固定宽度 */
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 10px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* overflow-y: auto;  */
}

.chat-window-scroll-auto {
  width: 100%; /* 固定宽度 */
  height: 100%;
  overflow-y: auto; 
}

.chat-window .user-message {
  align-self: flex-end; /* 用户消息右对齐 */
}

.chat-window .gpt-message {
  align-self: flex-start; /* 回复消息左对齐 */
}

/* User Message Styles */
.user-message {
  display: inline-block;  
  max-width: 600px; /* 确保消息框大小适当 */
  padding: 10px;
  margin: 10px 1% 10px auto; /* 右对齐 */
  border-radius: 10px;
  word-wrap: break-word;
  white-space: pre-wrap;
  background-color: #B5F892;
}

/* GPT Message Styles */
.gpt-message {
  display: inline-block;
  max-width: 750px; /* 确保消息框大小适当 */
  padding: 10px;
  margin: 10px auto 10px 0px; /* 左对齐 */
  border-radius: 10px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.gpt-message-container {
  display: flex;
  align-items: flex-start; /* 顶部对齐 */
}

.gpt-avatar {
  margin-right: 20px; /* 设置头像与文本之间的间距 */
  align-self: flex-start; /* 顶部对齐 */
}

.gpt-text {
  max-width: calc(100% - 34px); /* 考虑头像宽度，调整文本最大宽度 */
  display: inline-block; /* 确保文字与头像对齐 */
  vertical-align: top; /* 确保文字与头像顶部对齐 */
}

/* Markdown Styles */
.gpt-message table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.gpt-message table, 
.gpt-message th, 
.gpt-message td {
  border: 1px solid #ddd;
}

.gpt-message th, 
.gpt-message td {
  padding: 8px;
  text-align: left;
}

.gpt-message th {
  background-color: #f2f2f2;
}

.gpt-message tr:nth-child(even) {
  background-color: #f9f9f9;
}

.gpt-message h1,
.gpt-message h2,
.gpt-message h3,
.gpt-message h4,
.gpt-message h5,
.gpt-message h6 {
  line-height: 2; /* 设置行高 */
}

.gpt-message ul {
  list-style-position: inside;
  margin: 0px 0px; 
  padding: 0px;
  /* 适当减少间距 */
  display: flex;
  flex-direction: column;
  gap: 10px; 
}

/* 确保 ::marker 被移除 */
.gpt-message li::marker {
  padding-left: 1.5em;
}

.gpt-message ol {
  margin: -20px 0px -20px 0px;
  padding: 0px;
  text-align: left;
  position: relative;
  padding-left: 1.5em;
  /* 适当减少间距 */
  display: flex;
  flex-direction: column;
  gap: 5px; 
}

.gpt-message li {
  margin: 0px 10px; 
  padding: 0px 0px 0px 0px;
  text-align: left;
  position: relative;
  /* display: flex;
  flex-direction: column;
  gap: 5px;  */
  /* list-style: none; */
}

  /* 移除 <p> 元素的默认外边距 */
.gpt-message li p {
  margin: 0px;
  padding: 0px;
} 

/* .gpt-message strong {
  margin-bottom: 10px;
  /* position: absolute;
  top: 0; */
  /* left: 1.5em; */
/* }  */

.gpt-message pre {
  background-color: #f6f8fa;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
}

.gpt-message code {
  background-color: #f6f8fa;
  padding: 2px 5px;
  border-radius: 3px;
}

/* Chat Input Styles */
.chat-input-container {
  width: 800px; /* 使用百分比宽度 */
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: #ececec;
  border-radius: 8px;
  position: relative;
  padding-right: 80px;
}

textarea {
  flex: 1;
  padding: 15px 17px; /* 调整padding以使光标居中 */
  border: none; /* 隐藏边框 */
  background-color: #ececec; /* 背景颜色 */
  border-radius: 8px;
  resize: none;
  height: 50px; /* 设置初始高度为50px */
  font-size: 16px; /* 设置字体大小 */
  line-height: 20px; /* 调整line-height以使文字和光标居中 */
  overflow-y: scroll; /* 显示滚动条 */
  width: calc(100% - 60px); /* 确保textarea不会与按钮重叠 */
}

textarea:focus {
  border: none; /* 隐藏聚焦时的边框 */
  outline: none; /* 移除默认的聚焦外框 */
}

textarea::-webkit-scrollbar {
  width: 6px; /* 完全隐藏滚动条 */
}

textarea:hover::-webkit-scrollbar {
  width: 6px; /* 鼠标悬停时显示滚动条 */
}

textarea::-webkit-scrollbar-track {
  background: transparent;
}

textarea::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.chat-input-button {
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  position: absolute; /* 固定按钮位置 */
  bottom: -3px; /* 按钮与textarea底部对齐 */
  right: -5px; /* 距离右边5px */
}

button {
  padding: 10px 15px;
  background-color: rgb(21, 169, 13);
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  height: 35px; /* 固定按钮高度 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:hover {
  background-color: rgb(21, 169, 13);
}

button:disabled {
  background-color: #c0c0c0; /* 置灰背景色 */
  color: #fff; /* 确保禁用状态下文字仍为白色 */
  cursor: not-allowed; /* 禁用状态下的鼠标样式 */
  opacity: 0.6; /* 调整透明度以显示禁用效果 */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  main {
      width: 100%; 
      padding: 10px 0px;
  }

  .chat {
      height: 100%;
      width: 100%; 
  }

  .gpt-avatar {
      margin-right: 15px; /* 设置头像与文本之间的间距 */
      align-self: flex-start; /* 顶部对齐 */
  }

  .container {
      width: 100%; /* 占据整个宽度 */
      height: 74vh;
      padding: 15px 0px 15px 10px;
      overflow: hidden;
  }

  .user-message {
      max-width: 85%;
      margin: 0px 11px 0px 0px;
  }

  .gpt-message {
      max-width: 97%;
      margin: 30px 0px 0px -7px;
  }

  .chat-window {
      width: 100%;
  }
  .chat-window-scroll-auto {
    width: 100%; /* 固定宽度 */
    margin: 0px 0px 55px 0px; 
    overflow-y: auto; 
  }

  .chat-input-container {
      width: 97%; /* 占据整个宽度，减去左右各5px的间距 */
      margin: 0 auto;
      display: flex;
      align-items: center;
      background-color: #ececec; /* 背景颜色 */
      border-radius: 8px;
      padding-right: 80px; /* 添加右边内边距，确保按钮不会与输入框内容重叠 */
      position: fixed; /* 固定在视口 */
      bottom: 8px; /* 距离底部10px */
      left: 50%;
      transform: translateX(-50%); /* 水平居中 */
      z-index: 10; /* 确保输入框在最上层 */
  }

  textarea {
      flex: 1;
      padding: 15px 17px; /* 调整padding以使光标居中 */
      border: none; /* 隐藏边框 */
      background-color: #ececec; /* 背景颜色 */
      border-radius: 8px;
      resize: none;
      height: 50px; /* 设置初始高度为50px */
      font-size: 16px; /* 设置字体大小 */
      line-height: 20px; /* 调整line-height以使文字和光标居中 */
      overflow-y: scroll; /* 显示滚动条 */
      width: calc(100% - 60px); /* 确保textarea不会与按钮重叠 */
  }
  
  .chat-input-button {
      padding: 8px 12px;
      height: 50px; /* 固定按钮高度 */
      border: none;
      cursor: pointer;
      position: absolute; /* 固定按钮位置 */
      bottom: 0px; /* 按钮与textarea底部对齐 */
      right: -5px; /* 距离右边10px */
  }
  
  textarea:focus {
      border: none; /* 隐藏聚焦时的边框 */
      outline: none; /* 移除默认的聚焦外框 */
  }
  
  .textarea::-webkit-scrollbar {
      width: 6px; /* 完全隐藏滚动条 */
  }
  
  textarea:hover::-webkit-scrollbar {
      width: 6px; /* 鼠标悬停时显示滚动条 */
  }
  
  textarea::-webkit-scrollbar-track {
      background: transparent;
  }
  
  textarea::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
  }

  .chat-window::-webkit-scrollbar {
      display: inline; 
  }
  
  .chat-window:hover::-webkit-scrollbar {
      width: 8px;
  }
  
  .chat-window::-webkit-scrollbar-track {
      background: transparent;
  }
  
  .chat-window::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
  }
}