/* 全局样式 */
.home-container * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home-container body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  background-color: #f8f9fa;
  color: #333;
}

/* 容器样式 */
.home-container .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background: linear-gradient(135deg, #ffffff 0%, #ffffff 100%);
}

/* 卡片样式 */
.home-container .card {
  width: 100%;
  max-width: 480px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.08);
  padding: 40px;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.home-container .card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.12);
}

/* Logo样式 */
.home-container .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

.home-container .logo-icon {
  font-size: 32px;
  margin-right: 10px;
}

.home-container .logo-text {
  font-size: 24px;
  font-weight: 700;
  color: rgb(21, 169, 13);
  letter-spacing: 0.5px;
}

/* 头部样式 */
.home-container .header {
  text-align: center;
  margin-bottom: 30px;
}

.home-container .header h1 {
  font-size: 32px;
  font-weight: 700;
  color: #4f46e5;
  margin-bottom: 12px;
  line-height: 1.3;
}

.home-container .subtitle {
  color: #6b7280;
  font-size: 17px;
  line-height: 1.5;
}

/* 特点列表 */
.home-container .features {
  margin-bottom: 30px;
  background-color: #f9fafb;
  border-radius: 12px;
  padding: 20px;
  border: 1px solid #e5e7eb;
}

.home-container .feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.home-container .feature-item:last-child {
  margin-bottom: 0;
}

.home-container .feature-icon {
  width: 24px;
  height: 24px;
  background-color: rgb(21, 169, 13);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  font-size: 14px;
  flex-shrink: 0;
}

.home-container .feature-text {
  font-size: 15px;
  color: #4b5563;
  font-weight: 500;
}

/* 聊天气泡 */
.home-container .chat-bubble {
  position: absolute;
  padding: 8px 12px;
  background-color: #f3f4f6;
  border-radius: 18px;
  font-size: 12px;
  color: #4b5563;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  animation: float 3s ease-in-out infinite;
  z-index: 1;
}

.home-container .chat-bubble-1 {
  top: 40px;
  right: 30px;
  background-color: #e0e7ff;
  animation-delay: 0s;
}

.home-container .chat-bubble-2 {
  bottom: 350px;
  left: 20px;
  background-color: #dbeafe;
  animation-delay: 0.5s;
}

.home-container .chat-bubble-3 {
  /* bottom: 150px; */
  top: 100px;
  right: 40px;
  background-color: #c7d2fe;
  animation-delay: 1s;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

/* 按钮区域 */
.home-container .button-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  z-index: 2;
}

/* 加载动画 */
.home-container .loading-spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 0.8s linear infinite;
  margin-right: 8px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* 按钮样式 */
.home-container .chat-button {
  padding: 16px 20px;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, rgb(21, 169, 13), rgb(21, 169, 13));
  color: white;
  /* box-shadow: 0 4px 15px rgba(148, 206, 140, 0.45); */
  letter-spacing: 0.5px;
}

.home-container .chat-button:hover:not(:disabled) {
  background: linear-gradient(to right, rgb(21, 169, 13), rgb(21, 169, 13));
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(148, 206, 140, 0.45);
}

.home-container .chat-button:active:not(:disabled) {
  transform: translateY(0);
}

.home-container .chat-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* 服务条款 */
.home-container .terms {
  margin-top: 25px;
  text-align: center;
  font-size: 13px;
  color: #9ca3af;
}

.home-container .terms a {
  color: rgb(21, 169, 13);
  text-decoration: none;
  margin: 0 4px;
  transition: color 0.2s;
}

.home-container .terms a:hover {
  color: rgb(21, 169, 13);
  text-decoration: underline;
}

/* 页脚 */
.home-container .footer {
  margin-top: 20px;
  color: #9ca3af;
  font-size: 14px;
  text-align: center;
  width: 100%;
  max-width: 480px;
}

.home-container .footer-content {
  padding: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.home-container .footer-tagline {
  margin-top: 5px;
  font-size: 13px;
  color: #9ca3af;
}

/* 响应式设计 */
@media (max-width: 520px) {
  .home-container .card {
    padding: 30px 25px;
  }
  
  .home-container .header h1 {
    font-size: 28px;
  }

  .home-container .features {
    padding: 15px;
  }
  
  .home-container .chat-button {
    padding: 14px 20px;
    font-size: 16px;
  }
  
  .home-container .chat-bubble {
    display: none;
  }
}

